var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.open,
        confirmLoading: _vm.confirmLoading,
      },
      on: { ok: _vm.submitForm, cancel: _vm.cancel },
    },
    [
      _c(
        "a-form-model",
        { ref: "form", attrs: { model: _vm.form, rules: _vm.rules } },
        [
          _c(
            "a-form-model-item",
            { attrs: { label: "用户名", prop: "userName" } },
            [
              _c("a-input", {
                attrs: { disabled: true },
                model: {
                  value: _vm.form.userName,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "userName", $$v)
                  },
                  expression: "form.userName",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: {
                "has-feedback": "",
                label: "新密码",
                prop: "newPassword",
              },
            },
            [
              _c("a-input-password", {
                attrs: { placeholder: "请输入新密码" },
                model: {
                  value: _vm.form.newPassword,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "newPassword", $$v)
                  },
                  expression: "form.newPassword",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: {
                "has-feedback": "",
                label: "确认密码",
                prop: "confirmPassword",
              },
            },
            [
              _c("a-input-password", {
                attrs: { placeholder: "请确认密码" },
                model: {
                  value: _vm.form.confirmPassword,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "confirmPassword", $$v)
                  },
                  expression: "form.confirmPassword",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }